import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7a4fa7b3 = () => interopDefault(import('../pages/accessibilite.vue' /* webpackChunkName: "pages/accessibilite" */))
const _6c3417f2 = () => interopDefault(import('../pages/bureau-etude-urbanisme.vue' /* webpackChunkName: "pages/bureau-etude-urbanisme" */))
const _2ef9003f = () => interopDefault(import('../pages/collectivites-territoriales.vue' /* webpackChunkName: "pages/collectivites-territoriales" */))
const _258c184b = () => interopDefault(import('../pages/confidentialite.vue' /* webpackChunkName: "pages/confidentialite" */))
const _27f0bc04 = () => interopDefault(import('../pages/ddt-ddtm-dreal.vue' /* webpackChunkName: "pages/ddt-ddtm-dreal" */))
const _d59d8810 = () => interopDefault(import('../pages/exports.vue' /* webpackChunkName: "pages/exports" */))
const _05eae175 = () => interopDefault(import('../pages/guide.vue' /* webpackChunkName: "pages/guide" */))
const _05168c65 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _12ea22a0 = () => interopDefault(import('../pages/loi-climat-et-resilience.vue' /* webpackChunkName: "pages/loi-climat-et-resilience" */))
const _145d9fba = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _ed1a53ca = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _5c9f6d60 = () => interopDefault(import('../pages/validation.vue' /* webpackChunkName: "pages/validation" */))
const _4fab1961 = () => interopDefault(import('../pages/ddt/enquete_overview.vue' /* webpackChunkName: "pages/ddt/enquete_overview" */))
const _55036d4e = () => interopDefault(import('../pages/dev/api.vue' /* webpackChunkName: "pages/dev/api" */))
const _3d407a5a = () => interopDefault(import('../pages/stats/state.vue' /* webpackChunkName: "pages/stats/state" */))
const _7904fe63 = () => interopDefault(import('../pages/stats/urba.vue' /* webpackChunkName: "pages/stats/urba" */))
const _418b202a = () => interopDefault(import('../pages/login/collectivites/explain.vue' /* webpackChunkName: "pages/login/collectivites/explain" */))
const _104b49de = () => interopDefault(import('../pages/login/collectivites/signin.vue' /* webpackChunkName: "pages/login/collectivites/signin" */))
const _24e1a054 = () => interopDefault(import('../pages/login/collectivites/signup.vue' /* webpackChunkName: "pages/login/collectivites/signup" */))
const _2e0c782f = () => interopDefault(import('../pages/login/ddt/explain.vue' /* webpackChunkName: "pages/login/ddt/explain" */))
const _e295adcc = () => interopDefault(import('../pages/login/ddt/signin.vue' /* webpackChunkName: "pages/login/ddt/signin" */))
const _b96900e0 = () => interopDefault(import('../pages/login/ddt/signup.vue' /* webpackChunkName: "pages/login/ddt/signup" */))
const _518cf44b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _41dbf452 = () => interopDefault(import('../pages/collectivites/_collectiviteId.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId" */))
const _6c3ae1cc = () => interopDefault(import('../pages/collectivites/_collectiviteId/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/index" */))
const _6d4418a0 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees" */))
const _bba0674e = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/data.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/data" */))
const _0639f14e = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geobretagne.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geobretagne" */))
const _038365b0 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geoide.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geoide" */))
const _275c0328 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/georisques.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/georisques" */))
const _f89f8166 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/gpu.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/gpu" */))
const _532a7e12 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/inpn.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/inpn" */))
const _52a637a6 = () => interopDefault(import('../pages/collectivites/_collectiviteId/glossaire.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/glossaire" */))
const _60b2a2da = () => interopDefault(import('../pages/collectivites/_collectiviteId/pac.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/pac" */))
const _64335424 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/index" */))
const _bc175200 = () => interopDefault(import('../pages/collectivites/_collectiviteId/ressources.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/ressources" */))
const _59544af3 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/add" */))
const _4c444594 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/signup.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/signup" */))
const _4edc3e0c = () => interopDefault(import('../pages/collectivites/_collectiviteId/procedures/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/procedures/add" */))
const _481280e2 = () => interopDefault(import('../pages/ddt/_departement.vue' /* webpackChunkName: "pages/ddt/_departement" */))
const _573f55e5 = () => interopDefault(import('../pages/ddt/_departement/index.vue' /* webpackChunkName: "pages/ddt/_departement/index" */))
const _7a85dec6 = () => interopDefault(import('../pages/ddt/_departement/collectivites/index.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/index" */))
const _0f98a065 = () => interopDefault(import('../pages/ddt/_departement/pac.vue' /* webpackChunkName: "pages/ddt/_departement/pac" */))
const _4b4d20c0 = () => interopDefault(import('../pages/ddt/_departement/procedures/index.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/index" */))
const _32aa67d9 = () => interopDefault(import('../pages/ddt/_departement/procedures/choix-collectivite.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/choix-collectivite" */))
const _75f9b17c = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/commune.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/commune" */))
const _bff51342 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/epci.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/epci" */))
const _b68b6b72 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/procedure/add.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/procedure/add" */))
const _b004df6e = () => interopDefault(import('../pages/frise/_procedureId/index.vue' /* webpackChunkName: "pages/frise/_procedureId/index" */))
const _69c6b3ce = () => interopDefault(import('../pages/print/_projectId.vue' /* webpackChunkName: "pages/print/_projectId" */))
const _67377441 = () => interopDefault(import('../pages/trames/_githubRef.vue' /* webpackChunkName: "pages/trames/_githubRef" */))
const _513bb8fc = () => interopDefault(import('../pages/documents/_githubRef/pac.vue' /* webpackChunkName: "pages/documents/_githubRef/pac" */))
const _6b4d6750 = () => interopDefault(import('../pages/frise/_procedureId/add.vue' /* webpackChunkName: "pages/frise/_procedureId/add" */))
const _75358322 = () => interopDefault(import('../pages/frise/_procedureId/invite.vue' /* webpackChunkName: "pages/frise/_procedureId/invite" */))
const _09ff2522 = () => interopDefault(import('../pages/frise/_procedureId/_eventId.vue' /* webpackChunkName: "pages/frise/_procedureId/_eventId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibilite",
    component: _7a4fa7b3,
    name: "accessibilite"
  }, {
    path: "/bureau-etude-urbanisme",
    component: _6c3417f2,
    name: "bureau-etude-urbanisme"
  }, {
    path: "/collectivites-territoriales",
    component: _2ef9003f,
    name: "collectivites-territoriales"
  }, {
    path: "/confidentialite",
    component: _258c184b,
    name: "confidentialite"
  }, {
    path: "/ddt-ddtm-dreal",
    component: _27f0bc04,
    name: "ddt-ddtm-dreal"
  }, {
    path: "/exports",
    component: _d59d8810,
    name: "exports"
  }, {
    path: "/guide",
    component: _05eae175,
    name: "guide"
  }, {
    path: "/login",
    component: _05168c65,
    name: "login"
  }, {
    path: "/loi-climat-et-resilience",
    component: _12ea22a0,
    name: "loi-climat-et-resilience"
  }, {
    path: "/mentions-legales",
    component: _145d9fba,
    name: "mentions-legales"
  }, {
    path: "/stats",
    component: _ed1a53ca,
    name: "stats"
  }, {
    path: "/validation",
    component: _5c9f6d60,
    name: "validation"
  }, {
    path: "/ddt/enquete_overview",
    component: _4fab1961,
    name: "ddt-enquete_overview"
  }, {
    path: "/dev/api",
    component: _55036d4e,
    name: "dev-api"
  }, {
    path: "/stats/state",
    component: _3d407a5a,
    name: "stats-state"
  }, {
    path: "/stats/urba",
    component: _7904fe63,
    name: "stats-urba"
  }, {
    path: "/login/collectivites/explain",
    component: _418b202a,
    name: "login-collectivites-explain"
  }, {
    path: "/login/collectivites/signin",
    component: _104b49de,
    name: "login-collectivites-signin"
  }, {
    path: "/login/collectivites/signup",
    component: _24e1a054,
    name: "login-collectivites-signup"
  }, {
    path: "/login/ddt/explain",
    component: _2e0c782f,
    name: "login-ddt-explain"
  }, {
    path: "/login/ddt/signin",
    component: _e295adcc,
    name: "login-ddt-signin"
  }, {
    path: "/login/ddt/signup",
    component: _b96900e0,
    name: "login-ddt-signup"
  }, {
    path: "/",
    component: _518cf44b,
    name: "index"
  }, {
    path: "/collectivites/:collectiviteId?",
    component: _41dbf452,
    children: [{
      path: "",
      component: _6c3ae1cc,
      name: "collectivites-collectiviteId"
    }, {
      path: "donnees",
      component: _6d4418a0,
      name: "collectivites-collectiviteId-donnees",
      children: [{
        path: "data",
        component: _bba0674e,
        name: "collectivites-collectiviteId-donnees-data"
      }, {
        path: "geobretagne",
        component: _0639f14e,
        name: "collectivites-collectiviteId-donnees-geobretagne"
      }, {
        path: "geoide",
        component: _038365b0,
        name: "collectivites-collectiviteId-donnees-geoide"
      }, {
        path: "georisques",
        component: _275c0328,
        name: "collectivites-collectiviteId-donnees-georisques"
      }, {
        path: "gpu",
        component: _f89f8166,
        name: "collectivites-collectiviteId-donnees-gpu"
      }, {
        path: "inpn",
        component: _532a7e12,
        name: "collectivites-collectiviteId-donnees-inpn"
      }]
    }, {
      path: "glossaire",
      component: _52a637a6,
      name: "collectivites-collectiviteId-glossaire"
    }, {
      path: "pac",
      component: _60b2a2da,
      name: "collectivites-collectiviteId-pac"
    }, {
      path: "prescriptions",
      component: _64335424,
      name: "collectivites-collectiviteId-prescriptions"
    }, {
      path: "ressources",
      component: _bc175200,
      name: "collectivites-collectiviteId-ressources"
    }, {
      path: "prescriptions/add",
      component: _59544af3,
      name: "collectivites-collectiviteId-prescriptions-add"
    }, {
      path: "prescriptions/signup",
      component: _4c444594,
      name: "collectivites-collectiviteId-prescriptions-signup"
    }, {
      path: "procedures/add",
      component: _4edc3e0c,
      name: "collectivites-collectiviteId-procedures-add"
    }]
  }, {
    path: "/ddt/:departement?",
    component: _481280e2,
    children: [{
      path: "",
      component: _573f55e5,
      name: "ddt-departement"
    }, {
      path: "collectivites",
      component: _7a85dec6,
      name: "ddt-departement-collectivites"
    }, {
      path: "pac",
      component: _0f98a065,
      name: "ddt-departement-pac"
    }, {
      path: "procedures",
      component: _4b4d20c0,
      name: "ddt-departement-procedures"
    }, {
      path: "procedures/choix-collectivite",
      component: _32aa67d9,
      name: "ddt-departement-procedures-choix-collectivite"
    }, {
      path: "collectivites/:collectiviteId/commune",
      component: _75f9b17c,
      name: "ddt-departement-collectivites-collectiviteId-commune"
    }, {
      path: "collectivites/:collectiviteId/epci",
      component: _bff51342,
      name: "ddt-departement-collectivites-collectiviteId-epci"
    }, {
      path: "collectivites/:collectiviteId/procedure/add",
      component: _b68b6b72,
      name: "ddt-departement-collectivites-collectiviteId-procedure-add"
    }]
  }, {
    path: "/frise/:procedureId",
    component: _b004df6e,
    name: "frise-procedureId"
  }, {
    path: "/print/:projectId?",
    component: _69c6b3ce,
    name: "print-projectId"
  }, {
    path: "/trames/:githubRef?",
    component: _67377441,
    name: "trames-githubRef"
  }, {
    path: "/documents/:githubRef?/pac",
    component: _513bb8fc,
    name: "documents-githubRef-pac"
  }, {
    path: "/frise/:procedureId?/add",
    component: _6b4d6750,
    name: "frise-procedureId-add"
  }, {
    path: "/frise/:procedureId?/invite",
    component: _75358322,
    name: "frise-procedureId-invite"
  }, {
    path: "/frise/:procedureId?/:eventId",
    component: _09ff2522,
    name: "frise-procedureId-eventId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
