
import axios from 'axios'

const validationBetaDDT = []

export default {
  name: 'DdtLayout',
  data () {
    return {
      isLoading: true
      // showTally: false
    }
  },
  computed: {
    isAllowed () {
      // console.log('this.$user?.profile: ', this.$user.profile)
      return (this.$user?.profile?.side === 'etat' && this.$user?.profile?.verified) || this.$user.profile.is_admin || this.$isDev
    },
    trameRef () {
      const scopes = { ddt: 'dept', dreal: 'region' }
      const poste = this.$user.profile.poste
      const code = poste === 'ddt' ? this.$user.profile.departement : this.$user.profile.region

      return `${scopes[poste]}-${this.$options.filters.deptToRef(code)}`
    },
    ddtBetaTest () {
      return validationBetaDDT.includes(this.$route.params.departement)
    }
  },
  async mounted () {
    await this.$user.isReady
    this.isLoading = false

    if (this.$user.profile.side !== 'etat') {
      console.warn('Page réservée au side État')
      this.$router.push('/')
    }

    // const displayedKey = 'tally-displayed-m6kNJP'
    // const formNb = window.localStorage.getItem(displayedKey) || 0
    // if (formNb < 1) {
    //   this.showTally = true
    //   window.Tally.loadEmbeds()
    //   localStorage.setItem(displayedKey, +formNb + 1)
    // }

    if (this.$route.query.contact) {
      axios({
        url: '/api/pipedrive/contacted',
        method: 'post',
        data: {
          email: this.$route.query.contact
        }
      })
    }
  }
}
